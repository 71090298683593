<!-- <app-top-header /> -->
<div [class.scrolled]="scrolled" class="sticky z-10 top-0 w-full">
  <div
    class="w-full bg-white shadow-secondary/10 shadow-xl transition-all duration-200 sticky top-0 py-3 z-50 supports-backdrop-blur:bg-primary/70"
  >
    <div [class]="'container'">
      <div class="flex items-center justify-between w-full mx-auto">
        <div
          class="header-logo w-[170px] md:w-[190px] xl:w-[220px]"
          routerLink="/"
        >
          <img
            appScroll
            class="w-full drop-shadow-md active:scale-95 duration-200 cursor-pointer"
            src="assets/auth/clr-logo.png"
            alt="logo"
          />
        </div>

        <ul class="lg:inline-flex gap-2 font-Roboto text-primary hidden">
          <!-- <li
            class="text-[14px] lg:text-[15px] py-2 px-4 xl:px-4 cursor-pointer hover:text-secondary active:scale-90 duration-150"
          >
            Consult Now
          </li> -->

          <button
            mat-button
            class="text-[14px] flex justify-between items-center lg:text-[15px] py-2 px-4 xl:px-4 cursor-pointer hover:text-secondary active:scale-90 duration-150"
            [matMenuTriggerFor]="menu"
          >
            Consult Now
            <svg
              width="18px"
              height="18px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M7 10L12 15L17 10"
                  stroke="#000000"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </g>
            </svg>
          </button>
          <mat-menu #menu="matMenu" class="flex-col flex gap-3 mt-[20px]">
            <button mat-menu-item (click)="gotoSearchPage('')">
              All Doctors
            </button>
            <button mat-menu-item (click)="gotoSearchPage('Instant')">
              Instant
            </button>
            <button mat-menu-item (click)="gotoSearchPage('Online')">
              Online Schedule
            </button>
            <button mat-menu-item (click)="gotoSearchPage('Chamber')">
              Chamber
            </button>
          </mat-menu>
          <li
            (click)="onClickService()"
            title="coming soon"
            class="text-[14px] lg:text-[15px] py-2 px-4 xl:px-4 cursor-pointer hover:text-secondary active:scale-90 duration-150"
          >
            Our Services
          </li>
          <li
            [routerLink]="'/health-plan'"
            class="text-[14px] lg:text-[15px] py-2 px-4 xl:px-4 cursor-pointer hover:text-secondary active:scale-90 duration-150"
          >
            Health Plans
          </li>

          @if (!userType || userType == 'agent') {
          <li
            [routerLink]="'/agent-home'"
            class="text-[14px] lg:text-[15px] py-2 px-4 xl:px-4 cursor-pointer hover:text-secondary active:scale-90 duration-150"
          >
            Agent Portal
          </li>
          }
        </ul>

        <ul class="flex items-center text-primary font-poppins text-[16px]">
          @if (!userType) {

          <div class="hidden lg:flex items-center gap-4">
            <li class="cursor-pointer" routerLink="/login">Signin</li>
            <li
              routerLink="/signup"
              class="px-6 py-2 bg-primary cursor-pointer rounded-[25px] text-white"
            >
              Registration
            </li>
          </div>
          }@else {
          <ul class="lg:flex items-center hidden">
            <li class="flex gap-2 items-center">
              {{ authInfo.userType === "doctor" ? authInfo?.doctorTitle : "" }}
              {{ authInfo.fullName }}

              <div *ngIf="isAuthLogin" class="flex items-center">
                <button
                  [matMenuTriggerFor]="beforeMenu"
                  class="flex active:scale-95"
                >
                  <div
                    class="w-8 rounded-full border-[1px] border-secondary bg-secondary/20"
                  >
                    <svg
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM15 9C15 10.6569 13.6569 12 12 12C10.3431 12 9 10.6569 9 9C9 7.34315 10.3431 6 12 6C13.6569 6 15 7.34315 15 9ZM12 20.5C13.784 20.5 15.4397 19.9504 16.8069 19.0112C17.4108 18.5964 17.6688 17.8062 17.3178 17.1632C16.59 15.8303 15.0902 15 11.9999 15C8.90969 15 7.40997 15.8302 6.68214 17.1632C6.33105 17.8062 6.5891 18.5963 7.19296 19.0111C8.56018 19.9503 10.2159 20.5 12 20.5Z"
                          fill="#1C274C"
                        ></path>
                      </g>
                    </svg>
                  </div>
                </button>
                <mat-menu
                  #beforeMenu="matMenu"
                  class="mt-header-menu"
                  xPosition="before"
                >
                  <ul
                    class="menu menu-sm dropdown-content p-4 shadow border-secondary flex border-[1px] bg-blue-50 rounded-lg w-52"
                  >
                    <li [routerLink]="'/' + userType + '/dashboard'">
                      <a class="font-Roboto text-[16px] text-primary py-2">
                        <svg
                          width="20px"
                          height="20px"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            <rect width="24" height="24" fill="none"></rect>
                            <path
                              d="M15.024 22C16.2771 22 17.3524 21.9342 18.2508 21.7345C19.1607 21.5323 19.9494 21.1798 20.5646 20.5646C21.1798 19.9494 21.5323 19.1607 21.7345 18.2508C21.9342 17.3524 22 16.2771 22 15.024V12C22 10.8954 21.1046 10 20 10H12C10.8954 10 10 10.8954 10 12V20C10 21.1046 10.8954 22 12 22H15.024Z"
                              fill="#01204e"
                            ></path>
                            <path
                              d="M2 15.024C2 16.2771 2.06584 17.3524 2.26552 18.2508C2.46772 19.1607 2.82021 19.9494 3.43543 20.5646C4.05065 21.1798 4.83933 21.5323 5.74915 21.7345C5.83628 21.7538 5.92385 21.772 6.01178 21.789C7.09629 21.9985 8 21.0806 8 19.976L8 12C8 10.8954 7.10457 10 6 10H4C2.89543 10 2 10.8954 2 12V15.024Z"
                              fill="#01204e"
                            ></path>
                            <path
                              d="M8.97597 2C7.72284 2 6.64759 2.06584 5.74912 2.26552C4.8393 2.46772 4.05062 2.82021 3.4354 3.43543C2.82018 4.05065 2.46769 4.83933 2.26549 5.74915C2.24889 5.82386 2.23327 5.89881 2.2186 5.97398C2.00422 7.07267 2.9389 8 4.0583 8H19.976C21.0806 8 21.9985 7.09629 21.789 6.01178C21.772 5.92385 21.7538 5.83628 21.7345 5.74915C21.5322 4.83933 21.1798 4.05065 20.5645 3.43543C19.9493 2.82021 19.1606 2.46772 18.2508 2.26552C17.3523 2.06584 16.2771 2 15.024 2H8.97597Z"
                              fill="#01204e"
                            ></path>
                          </g></svg
                        >Dashboard
                      </a>
                    </li>
                    <li>
                      <a
                        class="font-Roboto text-[16px] text-primary py-2"
                        (click)="signOut()"
                      >
                        <svg
                          width="20px"
                          height="20px"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M16.125 12C16.125 11.5858 15.7892 11.25 15.375 11.25L4.40244 11.25L6.36309 9.56944C6.67759 9.29988 6.71401 8.8264 6.44444 8.51191C6.17488 8.19741 5.7014 8.16099 5.38691 8.43056L1.88691 11.4306C1.72067 11.573 1.625 11.7811 1.625 12C1.625 12.2189 1.72067 12.427 1.88691 12.5694L5.38691 15.5694C5.7014 15.839 6.17488 15.8026 6.44444 15.4881C6.71401 15.1736 6.67759 14.7001 6.36309 14.4306L4.40244 12.75L15.375 12.75C15.7892 12.75 16.125 12.4142 16.125 12Z"
                              fill="#01204e"
                            ></path>
                            <path
                              d="M9.375 8C9.375 8.70219 9.375 9.05329 9.54351 9.3055C9.61648 9.41471 9.71025 9.50848 9.81946 9.58145C10.0717 9.74996 10.4228 9.74996 11.125 9.74996L15.375 9.74996C16.6176 9.74996 17.625 10.7573 17.625 12C17.625 13.2426 16.6176 14.25 15.375 14.25L11.125 14.25C10.4228 14.25 10.0716 14.25 9.8194 14.4185C9.71023 14.4915 9.6165 14.5852 9.54355 14.6944C9.375 14.9466 9.375 15.2977 9.375 16C9.375 18.8284 9.375 20.2426 10.2537 21.1213C11.1324 22 12.5464 22 15.3748 22L16.3748 22C19.2032 22 20.6174 22 21.4961 21.1213C22.3748 20.2426 22.3748 18.8284 22.3748 16L22.3748 8C22.3748 5.17158 22.3748 3.75736 21.4961 2.87868C20.6174 2 19.2032 2 16.3748 2L15.3748 2C12.5464 2 11.1324 2 10.2537 2.87868C9.375 3.75736 9.375 5.17157 9.375 8Z"
                              fill="#01204e"
                            ></path>
                          </g></svg
                        >Sign-out
                      </a>
                    </li>
                  </ul>
                </mat-menu>
              </div>
            </li>
          </ul>
          }
          <div>
            <svg
              (click)="openHomeMenu()"
              fill="#01204e"
              width="20px"
              height="20px"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
              class="mr-2 ml-2 lg:hidden cursor-pointer"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M0 0h4v4H0V0zm0 6h4v4H0V6zm0 6h4v4H0v-4zM6 0h4v4H6V0zm0 6h4v4H6V6zm0 6h4v4H6v-4zm6-12h4v4h-4V0zm0 6h4v4h-4V6zm0 6h4v4h-4v-4z"
                  fill-rule="evenodd"
                ></path>
              </g>
            </svg>
          </div>
        </ul>
      </div>
    </div>
  </div>
</div>

<div
  [class]="
    isVisible
      ? 'home-menu z-10 relative show-home-menu overflow-y-scroll bg-primary/90 backdrop-blur-md custom-scrollbar'
      : 'home-menu z-10 relative hide-home-menu bg-primary/90 overflow-y-scroll backdrop-blur-md  custom-scrollbar'
  "
>
  <ul class="flex flex-col items-center justify-center py-5">
    <li>
      <div class="flex items-center">
        <div class="rounded-full overflow-hidden w-20 h-20">
          <img src="../../../../assets/person.png" class="w-full h-full" />
        </div>
      </div>
    </li>

    @if (isAuthLogin) {
    <li class="text-[20px] mt-4 text-center">
      {{ authInfo.userType === "doctor" ? authInfo?.doctorTitle : "" }}
      {{ authInfo.fullName }}
    </li>
    <li
      [routerLink]="'/' + userType + '/dashboard'"
      class="cursor-pointer mt-3 bg-secondary px-4 rounded-md"
    >
      <a class="text-[16px] py-2 flex gap-1 items-center">
        <svg
          viewBox="0 0 24 24"
          class="w-5 h-5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <rect width="24" height="24"></rect>
            <path
              d="M15.024 22C16.2771 22 17.3524 21.9342 18.2508 21.7345C19.1607 21.5323 19.9494 21.1798 20.5646 20.5646C21.1798 19.9494 21.5323 19.1607 21.7345 18.2508C21.9342 17.3524 22 16.2771 22 15.024V12C22 10.8954 21.1046 10 20 10H12C10.8954 10 10 10.8954 10 12V20C10 21.1046 10.8954 22 12 22H15.024Z"
              fill="#fff"
            ></path>
            <path
              d="M2 15.024C2 16.2771 2.06584 17.3524 2.26552 18.2508C2.46772 19.1607 2.82021 19.9494 3.43543 20.5646C4.05065 21.1798 4.83933 21.5323 5.74915 21.7345C5.83628 21.7538 5.92385 21.772 6.01178 21.789C7.09629 21.9985 8 21.0806 8 19.976L8 12C8 10.8954 7.10457 10 6 10H4C2.89543 10 2 10.8954 2 12V15.024Z"
              fill="#fff"
            ></path>
            <path
              d="M8.97597 2C7.72284 2 6.64759 2.06584 5.74912 2.26552C4.8393 2.46772 4.05062 2.82021 3.4354 3.43543C2.82018 4.05065 2.46769 4.83933 2.26549 5.74915C2.24889 5.82386 2.23327 5.89881 2.2186 5.97398C2.00422 7.07267 2.9389 8 4.0583 8H19.976C21.0806 8 21.9985 7.09629 21.789 6.01178C21.772 5.92385 21.7538 5.83628 21.7345 5.74915C21.5322 4.83933 21.1798 4.05065 20.5645 3.43543C19.9493 2.82021 19.1606 2.46772 18.2508 2.26552C17.3523 2.06584 16.2771 2 15.024 2H8.97597Z"
              fill="#fff"
            ></path>
          </g></svg
        >Dashboard
      </a>
    </li>
    }
  </ul>
  <ul class="border-t-[1px] border-gray-500 px-5 py-3">
    <li
      class="py-2 cursor-pointer hover:text-secondary flex gap-1"
      (click)="navigator('soowgood-point')"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="#fff"
        width="25px"
        height="25px"
        viewBox="0 0 24 24"
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
          <path
            d="M20 4H4v2h16V4zm1 10v-2l-1-5H4l-1 5v2h1v6h10v-6h4v6h2v-6h1zm-9 4H6v-4h6v4z"
          ></path>
        </g>
      </svg>
      Soowgood Point
    </li>
    <li
      class="py-2 cursor-pointer hover:text-secondary flex gap-2"
      (click)="navigator('soowgood-booth')"
    >
      <svg
        fill="#ffffff"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="20px"
        height="20px"
        viewBox="0 0 256 253"
        enable-background="new 0 0 256 253"
        xml:space="preserve"
        stroke="#ffffff"
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
          <path
            d="M122,219H76v-45h18v14h10v-14h18V219z M182,219h-46v-45h18v14h10v-14h18V219z M152,160h-46v-45h18v14h10v-14h18V160z M2,69 c0,13.678,9.625,25.302,22,29.576V233H2v18h252v-18h-22V98.554c12.89-3.945,21.699-15.396,22-29.554v-8H2V69z M65.29,68.346 c0,6.477,6.755,31.47,31.727,31.47c21.689,0,31.202-19.615,31.202-31.47c0,11.052,7.41,31.447,31.464,31.447 c21.733,0,31.363-20.999,31.363-31.447c0,14.425,9.726,26.416,22.954,30.154V233H42V98.594C55.402,94.966,65.29,82.895,65.29,68.346 z M222.832,22H223V2H34v20L2,54h252L222.832,22z"
          ></path>
        </g>
      </svg>
      Village Booth
    </li>
  </ul>

  <ul
    class="gap-2 ml-2 font-poppinsLight text-[14px] text-primary lg:hidden border-t-[1px] border-gray-500"
  >
    <li
      routerLink="/search"
      class="py-2 px-4 xl:px-4 cursor-pointer hover:text-secondary active:scale-90 duration-150"
    >
      Consult Now
    </li>
    <li
      routerLink="/"
      class="py-2 px-4 xl:px-4 cursor-pointer hover:text-secondary active:scale-90 duration-150"
    >
      Our Services
    </li>
    <li
      routerLink="/health-plan"
      class="py-2 px-4 xl:px-4 cursor-pointer hover:text-secondary active:scale-90 duration-150"
    >
      Healt Plan
    </li>
  </ul>

  @if (!isAuthLogin) {
  <ul class="border-t-[1px] border-gray-500 flex px-5 py-3 gap-4 flex-1">
    <li
      class="py-1 cursor-pointer bg-secondary px-6 rounded-md hover:text-secondary"
      routerLink="/login"
    >
      Signin
    </li>
    <li
      class="py-1 cursor-pointer px-6 bg-secondary rounded-md"
      routerLink="/signup"
    >
      Registration
    </li>
  </ul>
  <div class="divider text-white after:bg-white before:bg-white">OR</div>
  <p class="text-white">
    If you are agent
    <span class="text-secondary cursor-pointer" routerLink="/agent/login"
      >click here</span
    >
  </p>

  } @if (isAuthLogin) {
  <div
    (click)="signOut()"
    class="absolute w-full text-center cursor-pointer bottom-0 bg-secondary hover:bg-secondary/80 duration-150 left-0 py-3 text-white"
  >
    <a class="font-Roboto text-[18px] text-white py-2">
      <i class="fa-solid fa-right-from-bracket mr-1"></i>Logout
    </a>
  </div>
  }
</div>
