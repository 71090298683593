<div class="min-h-screen bg-gray-100 flex items-center justify-center">
  <div class="bg-white shadow-md rounded-lg max-w-lg w-full">
    <h2 class="text-2xl font-bold text-gray-800 mb-6 text-center">
      Blood Donation Form
    </h2>
    <form>
      <!-- Full Name -->
      <div class="mb-4">
        <label for="fullName" class="block text-gray-700 font-medium mb-2"
          >Full Name</label
        >
        <input
          type="text"
          id="fullName"
          name="fullName"
          class="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent"
          placeholder="Enter your full name"
        />
      </div>

      <!-- Email Address -->
      <div class="mb-4">
        <label for="email" class="block text-gray-700 font-medium mb-2"
          >Email Address</label
        >
        <input
          type="email"
          id="email"
          name="email"
          class="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent"
          placeholder="Enter your email address"
        />
      </div>

      <!-- Phone Number -->
      <div class="mb-4">
        <label for="phone" class="block text-gray-700 font-medium mb-2"
          >Phone Number</label
        >
        <input
          type="tel"
          id="phone"
          name="phone"
          class="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent"
          placeholder="Enter your phone number"
        />
      </div>

      <!-- Blood Type -->
      <div class="mb-4">
        <label for="bloodType" class="block text-gray-700 font-medium mb-2"
          >Blood Type</label
        >
        <select
          id="bloodType"
          name="bloodType"
          class="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent"
        >
          <option value="" disabled selected>Select your blood type</option>
          <option value="A+">A+</option>
          <option value="A-">A-</option>
          <option value="B+">B+</option>
          <option value="B-">B-</option>
          <option value="O+">O+</option>
          <option value="O-">O-</option>
          <option value="AB+">AB+</option>
          <option value="AB-">AB-</option>
        </select>
      </div>

      <!-- Donation Date -->
      <div class="mb-4">
        <label for="donationDate" class="block text-gray-700 font-medium mb-2"
          >Preferred Donation Date</label
        >
        <input
          type="date"
          id="donationDate"
          name="donationDate"
          class="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent"
        />
      </div>

      <!-- Submit Button -->
      <div class="mt-6">
        <button
          type="submit"
          class="w-full bg-secondary text-white font-medium py-2 rounded-lg hover:bg-primary focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</div>
