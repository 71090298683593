<!-- <app-loader *ngIf="isLoading" class="app-loader"></app-loader> -->
<router-outlet></router-outlet>
<!-- <app-splash></app-splash> -->
<!-- <ngx-facebook-messenger
  [fbInitParams]="{ xfbml: true, version: 'v17.0' }"
  [ngxFacebookMessengerOptions]="{ page_id: '110526654439956' }"
>
</ngx-facebook-messenger> -->
<!-- <div *ngIf="message">
  <h1>{{ message.notification.title }}</h1>
  <h2>{{ message.notification.body }}</h2>
</div>
<div *ngIf="!message">No Message Arrived</div>
<button (click)="requestPermission()">click</button> -->
