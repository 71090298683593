import { CampaignDoctorService } from './../../../../../proxy/services/campaign-doctor.service';
import {
  CampaignDoctorDto,
  CampaignDto,
} from './../../../../../proxy/dto-models/models';
import { Component, OnInit, signal, WritableSignal } from '@angular/core';
import { FilterModel } from 'src/app/proxy/dto-models';
import { DocumentsAttachmentService } from 'src/app/proxy/services';
import { DoctorStateService } from 'src/app/shared/services/states/doctors-states/doctor-state.service';
import { environment } from 'src/environments/environment';
import { CampaignService } from './../../../../../proxy/services/campaign.service';
import { AuthService } from './../../../../../shared/services/auth.service';
import { filter, map, Subscription } from 'rxjs';

@Component({
  selector: 'app-emergency-doctors',
  templateUrl: './emergency-doctors.component.html',
  styleUrl: './emergency-doctors.component.scss',
})
export class EmergencyDoctorsComponent implements OnInit {
  doctorList!: any[];
  isLoading!: boolean;
  liveDoctorList!: any[];
  filterModel: FilterModel = {
    offset: 0,
    limit: 0,
    pageNo: 1,
    pageSize: 2,
    sortBy: 'name',
    sortOrder: 'asc',
    isDesc: false,
  };
  private subscription: Subscription | null = null;
  userType: any;
  public picUrl = `${environment.apis.default.url}/`;
  campaignDetails: CampaignDto | null = null;
  loadingState: WritableSignal<{ skelton: boolean }> = signal({
    skelton: false,
  });

  constructor(
    private AuthService: AuthService,
    private CampaignService: CampaignService,
    private CampaignDoctorService: CampaignDoctorService
  ) {}
  ngOnInit(): void {
    this.userType = this.AuthService.authInfo()?.userType;
    this.getCampaign();
  }

  getCampaign() {
    this.loadingState.set({
      skelton: true,
    });
    this.subscription = this.CampaignService.getList()
      .pipe(map((res: any[]) => res.find((campaign, i) => campaign.isActive)))
      .subscribe({
        next: (res: CampaignDto) => {
          if (res.id) {
            this.CampaignDoctorService.getCampaignDoctorListByCampaignId(res.id)
              .pipe(
                map((doctos: any) =>
                  doctos.map((doctor: any) => ({
                    ...doctor,
                    profilePicUrl: this.generateProfilePicUrl(
                      doctor.profilePic
                    ),
                  }))
                )
              )
              .subscribe({
                next: (doctors: CampaignDoctorDto[]) => {
                  this.campaignDetails = {
                    ...res,
                    selectedDoctor: doctors,
                  };
                  this.loadingState.set({
                    skelton: false,
                  });
                },
              });
          }
        },
        error: (err) => {
          this.loadingState.set({
            skelton: false,
          });
        },
      });
  }
  public generateProfilePicUrl(path: string) {
    const prePaths: string = path || '';
    const re = /wwwroot/gi;
    const profilePic = prePaths.replace(re, '');
    return this.picUrl + profilePic;
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
