<div class="bg-white">
  <div class="container py-10 lg:py-14">
    <div class="w-fit mx-auto text-center">
      <h1 class="text-[26px] lg:text-[32px] font-poppinsBold font-semibold">
        STORIES OF SOOWGOOD
      </h1>
      <p class="text-[16px] lg:text-[18px] font-poppins text-gray-400">
        HEAR FROM OUR BELOVED CUSTOMERS
      </p>
    </div>
    <div class="grid grid-cols-2 lg:grid-cols-4 gap-4 md:gap-10 mt-10">
      <div
        class="lg:h-[280px] col-span-1 aspect-square lg:aspect-auto relative group"
      >
        <!-- <div class="bg-red-600/30 absolute top-0 w-full h-full"></div> -->
        <img
          class="w-full aspect-auto object-cover h-full group-hover:scale-105 duration-200"
          src="assets/story/s-1.jpg"
          alt=""
        />
      </div>
      <div
        class="lg:h-[280px] col-span-1 aspect-square lg:aspect-auto relative group"
      >
        <!-- <div class="bg-red-600/30 absolute top-0 w-full h-full"></div> -->
        <img
          class="w-full aspect-auto object-cover h-full group-hover:scale-105 duration-200"
          src="assets/story/s-2.jpg"
          alt=""
        />
      </div>
      <div
        class="lg:h-[280px] aspect-square lg:aspect-auto col-span-1 relative group"
      >
        <!-- <div class="bg-red-600/30 absolute top-0 w-full h-full"></div> -->
        <img
          class="w-full aspect-auto object-cover h-full group-hover:scale-105 duration-200"
          src="assets/story/s-3.jpg"
          alt=""
        />
      </div>
      <div
        class="lg:h-[280px] aspect-square lg:aspect-auto col-span-1 relative group"
      >
        <!-- <div class="bg-red-600/30 absolute top-0 w-full h-full"></div> -->

        <img
          class="w-full aspect-auto object-cover h-full group-hover:scale-105 duration-200"
          src="assets/story/s-4.jpg"
          alt=""
        />
      </div>
      <div
        class="lg:h-[280px] aspect-square lg:aspect-auto col-span-1 relative group"
      >
        <!-- <div class="bg-red-600/30 absolute top-0 w-full h-full"></div> -->

        <img
          class="w-full aspect-auto object-cover h-full group-hover:scale-105 duration-200"
          src="assets/story/s-5.jpg"
          alt=""
        />
      </div>
      <div
        class="lg:h-[280px] aspect-square lg:aspect-auto col-span-1 relative group"
      >
        <img
          class="w-full aspect-auto object-cover h-full group-hover:scale-105 duration-200"
          src="assets/story/s-6.jpg"
          alt=""
        />
      </div>
      <div
        class="lg:h-[280px] aspect-square lg:aspect-auto col-span-1 relative group"
      >
        <img
          class="w-full aspect-auto object-cover h-full group-hover:scale-105 duration-200"
          src="assets/story/s-7.jpg"
          alt=""
        />
      </div>
      <div
        class="lg:h-[280px] aspect-square lg:aspect-auto col-span-1 relative group"
      >
        <img
          class="w-full aspect-auto object-cover h-full group-hover:scale-105 duration-200"
          src="assets/story/s-8.jpg"
          alt=""
        />
      </div>
    </div>
  </div>
</div>
