<div>
  <div
    class="bg-primary h-[150px] text-white flex justify-center items-center font-poppinsBold text-[26px]"
  >
    Ambulance Service
  </div>
  <div class="container py-10">
    <div class="card card-compact bg-base-100 w-96 shadow-xl">
      <figure>
        <img src="assets/other/amb.png" alt="Shoes" />
      </figure>
      <div class="card-body">
        <h2 class="card-title">Jony Ambulace Services</h2>
        <p>Sasthya Seba</p>

        <div class="card-actions justify-end">
          <a href="https://jonyambulanceservice.com/"> Visit Now</a>
        </div>
      </div>
    </div>
  </div>
</div>
