<div
  id="emergency"
  class="bg-gray-50 py-10 lg:py-12 xl:py-14 2xl:py-20 lg:h-[90vh] 2xl:h-fit"
>
  <div class="max-w-[1400px] mx-auto p-4">
    <div
      class="py-5 lg:py-8 flex w-full lg:flex-row flex-col-reverse gap-5 items-center lg:justify-between"
    >
      @if (loadingState().skelton) {
      <app-text-skelton />
      }@else {
      <div class="text-center lg:text-left">
        <p class="font-Roboto text-secondary">{{ campaignDetails?.title }}</p>
        <h2
          class="text-[22px] text-primary md:text-[26px] lg:text-[34px] font-poppins font-semibold text-blue-gray-600"
        >
          {{ campaignDetails?.subTitle }}
        </h2>
      </div>
      }
      <img
        class="max-w-[180px] lg:max-w-[200px]"
        src="assets/other/1click-c.svg"
        alt="png"
      />
    </div>

    @if (loadingState().skelton) {
    <div class="grid grid-cols-1 max-w-full md:grid-cols-2 gap-10 mt-5">
      <app-skeleton></app-skeleton>
      <app-skeleton></app-skeleton>
    </div>
    }@else {
    <div
      class="grid gap-10 mt-5 w-full"
      [ngClass]="{
        'grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3':
          (campaignDetails?.selectedDoctor?.length ?? 0) > 1,
        'grid-cols-1': campaignDetails?.selectedDoctor?.length === 1
      }"
    >
      @for (doctorDetails of campaignDetails?.selectedDoctor; track $index) {
      <app-emergency-doctor-card
        [doctorDetails]="doctorDetails"
      ></app-emergency-doctor-card>
      } @empty { No Data Found! }
    </div>

    }
  </div>
</div>
