import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Route, RouterModule } from '@angular/router';
import { FooterModule } from 'src/app/layout-module/header-footer/footer/footer.module';
import { HeaderModule } from 'src/app/layout-module/header-footer/header/header.module';
import { ScrollDirective } from 'src/app/shared/directive/scroll/scroll.directive';
import { BookingReviewModule } from 'src/app/shared/modules/booking-review/booking-review.module';
import { LoaderModule } from 'src/app/shared/modules/loader/loader.module';
import { LoginInputModule } from 'src/app/shared/modules/login-input/login-input.module';
import { MaterialModulesModule } from 'src/app/shared/modules/material-modules/material-modules.module';
import { SkeletonModule } from 'src/app/shared/modules/skeleton/skeleton.module';
import { AccoutDeleteRequestComponent } from '../accout-delete-request/accout-delete-request.component';
import { AppointmentSuccessComponent } from '../appointment-success/appointment-success.component';
import { PaymentCancelComponent } from '../payment-cancel/payment-cancel.component';
import { PaymentFaildComponent } from '../payment-faild/payment-faild.component';
import { PaymentSuccessComponent } from '../payment-success/payment-success.component';
import { AppMarketingComponent } from './components/app-marketing/app-marketing.component';
import { BannerComponent } from './components/banner/banner.component';
import { ClientFeedbackComponent } from './components/client-feedback/client-feedback.component';
import { FeaturedDoctorCardComponent } from './components/featured-doctor-card/featured-doctor-card.component';
import { FeaturedDoctorsComponent } from './components/featured-doctors/featured-doctors.component';
import { LiveConsultBookingDialogComponent } from './components/live-doctors/live-consult-booking-dialog/live-consult-booking-dialog.component';
import { LiveDoctorsComponent } from './components/live-doctors/live-doctors.component';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { PartnersCardComponent } from './components/partners-card/partners-card.component';
import { PartnersComponent } from './components/partners/partners.component';
import { PricingCardIncludeListComponent } from './components/pricing/pricing-card-include-list/pricing-card-include-list.component';
import { PricingCardComponent } from './components/pricing/pricing-card/pricing-card.component';
import { PricingModalComponent } from './components/pricing/pricing-modal/pricing-modal.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { SectionTitleComponent } from './components/section-title/section-title.component';
import { ServiceCardComponent } from './components/service-card/service-card.component';
import { ServicesComponent } from './components/services/services.component';
import { SignupMarketingBannerComponent } from './components/signup-marketing-banner/signup-marketing-banner.component';
import { StoriesComponent } from './components/stories/stories.component';
import { UpcomingComponent } from './components/upcoming/upcoming.component';
import { LandingPageComponent } from './landing-page.component';
import { LiveDoctorCardModule } from './components/live-doctors/live-doctor-card/live-doctor-card.module';
import { EmergencyDoctorsComponent } from './components/emergency-doctors/emergency-doctors.component';
import { EmergencyDoctorCardComponent } from './components/emergency-doctors/emergency-doctor-card/emergency-doctor-card.component';
import { BanglaNumeralsPipe } from 'src/app/shared/pipes/english-bangla-number.pipe';
const routes: Route[] = [
  {
    path: '',
    component: LandingPageComponent,
  },
  {
    path: 'acoount-delete-request',
    component: AccoutDeleteRequestComponent,
  },
  {
    path: 'appointment-success',
    component: AppointmentSuccessComponent,
  },
  {
    path: 'payment-success',
    component: PaymentSuccessComponent,
  },
  {
    path: 'payment-cancel',
    component: PaymentCancelComponent,
  },
  {
    path: 'payment-faild',
    component: PaymentFaildComponent,
  },
  {
    path: 'upcoming',
    component: UpcomingComponent,
  },
];
@NgModule({
  declarations: [
    LandingPageComponent,
    BannerComponent,
    LiveDoctorsComponent,

    PricingComponent,
    SignupMarketingBannerComponent,
    LiveConsultBookingDialogComponent,
    AppMarketingComponent,
    ClientFeedbackComponent,
    NewsletterComponent,
    PricingCardComponent,
    PricingCardIncludeListComponent,
    PricingModalComponent,
    AccoutDeleteRequestComponent,
    ServiceCardComponent,
    PartnersComponent,
    PartnersCardComponent,
    ServicesComponent,
    SectionTitleComponent,
    FeaturedDoctorsComponent,
    FeaturedDoctorCardComponent,
    StoriesComponent,
    AppointmentSuccessComponent,
    EmergencyDoctorsComponent,
    EmergencyDoctorCardComponent,
    BanglaNumeralsPipe,
  ],
  imports: [
    CommonModule,
    ScrollDirective,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    MaterialModulesModule,
    BookingReviewModule,
    NgOptimizedImage,
    FormsModule,
    HeaderModule,
    FooterModule,
    SkeletonModule,
    LoginInputModule,
    LoaderModule,
    LiveDoctorCardModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LandingPageModule {}
