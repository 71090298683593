<div [@fadeInBlur] class="w-full relative">
  <div #scrolltop></div>
  @if (bottomBtn) {
  <div
    appScroll
    class="fixed bottom-10 z-10 right-10 cursor-pointer bg-secondary p-4 text-white rounded-md"
  >
    <i class="fa-solid fa-arrow-up"></i>
  </div>
  }
  <app-header></app-header>
  <app-banner></app-banner>
  @if (isShowOffer) {
  <app-emergency-doctors></app-emergency-doctors>
  } @defer (on viewport; prefetch on idle) {
  <app-services /> }@placeholder {
  <div class="flex flex-shrink-1 w-full h-[300px] justify-center items-center">
    Loading...
  </div>
  } @defer (on viewport; prefetch on idle) {
  <app-live-doctors id="instantCall"></app-live-doctors> }@placeholder {
  <div class="flex flex-shrink-1 w-full h-[300px] justify-center items-center">
    Loading...
  </div>
  }
  <!-- <app-featured-doctors /> -->

  @defer (on viewport; prefetch on idle) {
  <app-app-marketing></app-app-marketing> }@placeholder {
  <div class="flex flex-shrink-1 w-full h-[300px] justify-center items-center">
    Loading...
  </div>
  } @defer (on viewport; prefetch on idle) {
  <app-stories /> }@placeholder {
  <div class="flex flex-shrink-1 w-full h-[300px] justify-center items-center">
    Loading...
  </div>
  }

  <!-- <app-signup-marketing-banner /> -->

  @defer (on viewport; prefetch on idle) {
  <app-partners> </app-partners>
  }@placeholder {
  <div class="flex flex-shrink-1 w-full h-[300px] justify-center items-center">
    Loading...
  </div>
  }
</div>

<!-- <app-splash></app-splash> -->
<app-footer></app-footer>
