<div class="bg-primary flex items-center">
  <div class="container">
    <div class="grid relative overflow-x-hidden lg:grid-cols-2 grid-cols-1">
      <div class="hidden justify-center lg:flex">
        <img
          loading="lazy"
          class="absolute max-h-[550px] bottom-0"
          ngSrc="assets/other/phone.png"
          width="450"
          height="500"
          alt=""
        />
      </div>
      <div class="py-10 lg:py-[100px]">
        <h3
          class="text-[14px] md:text-[15px] lg:text-[20px] text-secondary font-semibold"
        >
          Transform Your Healthcare Experience with Soowgood!
        </h3>
        <h1
          class="text-[26px] md:text-[34px] lg:text-[44px] mb-6 text-white font-bold"
        >
          Download Our <span class="text-secondary">Soowgood</span> App From
          Your Gadget
        </h1>
        <p class="lg:text-[16px] text-[14px] text-gray-300">
          Access top-quality medical services anytime, anywhere. Download the
          Soowgood app to connect with doctors, book appointments, order
          medications, and more – all from the comfort of your home.
        </p>

        <div class="flex gap-3 md:gap-6 mt-10">
          <div
            class="w-fit bg-white sm:h-[60px] rounded-lg flex gap-4 items-center py-2 lg:py-3 px-3 lg:px-6 md:p-4"
          >
            <svg
              height="34px"
              width="34px"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 511.999 511.999"
              xml:space="preserve"
              fill="#000000"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <g>
                  <path
                    style="fill: #32bbff"
                    d="M382.369,175.623C322.891,142.356,227.427,88.937,79.355,6.028 C69.372-0.565,57.886-1.429,47.962,1.93l254.05,254.05L382.369,175.623z"
                  ></path>
                  <path
                    style="fill: #32bbff"
                    d="M47.962,1.93c-1.86,0.63-3.67,1.39-5.401,2.308C31.602,10.166,23.549,21.573,23.549,36v439.96 c0,14.427,8.052,25.834,19.012,31.761c1.728,0.917,3.537,1.68,5.395,2.314L302.012,255.98L47.962,1.93z"
                  ></path>
                  <path
                    style="fill: #32bbff"
                    d="M302.012,255.98L47.956,510.035c9.927,3.384,21.413,2.586,31.399-4.103 c143.598-80.41,237.986-133.196,298.152-166.746c1.675-0.941,3.316-1.861,4.938-2.772L302.012,255.98z"
                  ></path>
                </g>
                <path
                  style="fill: #2c9fd9"
                  d="M23.549,255.98v219.98c0,14.427,8.052,25.834,19.012,31.761c1.728,0.917,3.537,1.68,5.395,2.314 L302.012,255.98H23.549z"
                ></path>
                <path
                  style="fill: #29cc5e"
                  d="M79.355,6.028C67.5-1.8,53.52-1.577,42.561,4.239l255.595,255.596l84.212-84.212 C322.891,142.356,227.427,88.937,79.355,6.028z"
                ></path>
                <path
                  style="fill: #d93f21"
                  d="M298.158,252.126L42.561,507.721c10.96,5.815,24.939,6.151,36.794-1.789 c143.598-80.41,237.986-133.196,298.152-166.746c1.675-0.941,3.316-1.861,4.938-2.772L298.158,252.126z"
                ></path>
                <path
                  style="fill: #ffd500"
                  d="M488.45,255.98c0-12.19-6.151-24.492-18.342-31.314c0,0-22.799-12.721-92.682-51.809l-83.123,83.123 l83.204,83.205c69.116-38.807,92.6-51.892,92.6-51.892C482.299,280.472,488.45,268.17,488.45,255.98z"
                ></path>
                <path
                  style="fill: #ffaa00"
                  d="M470.108,287.294c12.191-6.822,18.342-19.124,18.342-31.314H294.303l83.204,83.205 C446.624,300.379,470.108,287.294,470.108,287.294z"
                ></path>
              </g>
            </svg>
            <a
              href="https://play.google.com/store/apps/details?id=com.oneworld.soowgood&pcampaignid=web_share"
            >
              <p class="text-[12px] mb-0 text-primary font-poppins">Download</p>
              <h2
                class="text-[16px] lg:text-[18px] font-semibold text-primary font-poppins"
              >
                Play Store
              </h2>
            </a>
          </div>

          <div
            class="bg-white sm:h-[60px] rounded-lg flex items-center py-2 lg:py-3 px-3 lg:px-6 md:p-4 gap-4 w-fit"
          >
            <i class="fa-brands text-[30px] md:text-[40px] fa-apple"></i>
            <a
              href="https://apps.apple.com/us/app/soowgood/id1659850704?platform=iphone"
            >
              <p class="text-[12px] mb-0 text-primary font-poppins">Download</p>
              <h2
                class="text-[16px] lg:text-[18px] font-semibold text-primary font-poppins"
              >
                App Store
              </h2>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
