import { PlatformFacilityService } from './../../../../../proxy/services/platform-facility.service';
import Swiper, { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';

import { Component, OnInit, effect } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

import { PlatformFacilityInputDto } from 'src/app/proxy/input-dto';
import { map } from 'rxjs';
import { trigger, transition, style, animate } from '@angular/animations';
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  animations: [
    trigger('fadeInSlideLeft', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(-100%)' }), // Start with opacity 0 and slid out to the left
        animate(
          '600ms ease-out',
          style({ opacity: 1, transform: 'translateX(0)' }) // Animate to full opacity and slide to position
        ),
      ]),
    ]),
    trigger('fadeInSlideRight', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(100%)' }), // Start with opacity 0 and slid out to the left
        animate(
          '600ms ease-out',
          style({ opacity: 1, transform: 'translateX(0)' }) // Animate to full opacity and slide to position
        ),
      ]),
    ]),
  ],
})
export class BannerComponent implements OnInit {
  serviceList!: PlatformFacilityInputDto[];
  searchForm!: FormGroup;
  service: any;
  searchText = '';
  authUser: any;
  profileStep: any;
  userRole: any;
  navlink: any;
  swiper!: Swiper;
  isOtpLoading: any = false;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private NormalAuth: AuthService,
    private PlatformFacilityService: PlatformFacilityService
  ) {
    effect(() => {
      console.log(this.NormalAuth.isOtpLoading());

      this.isOtpLoading = this.NormalAuth.isOtpLoading();
    });
  }

  ngOnInit(): void {
    this.loadForm();
    this.getPlatformList();
    this.authUser = this.NormalAuth.authInfo();
    this.profileStep = this.authUser ? this.authUser.profileStep : null;
    this.userRole = this.authUser ? this.authUser.userType : null;
    //this.navlink = this.userRole.toString().toLowerCase() + '/dashboard';
    this.searchForm.get('service')?.valueChanges.subscribe((service) => {
      this.service = service;
    });
    this.searchForm.get('searchText')?.valueChanges.subscribe((searchText) => {
      this.searchText = searchText;
    });
  }

  loadForm() {
    this.searchForm = this.fb.group({
      service: ['0'],
      searchText: [''],
    });
  }
  onChangeService(e: any) {}
  goToServicePage() {
    const searchText = this.searchForm.get('searchText')?.value;

    if (searchText) {
      this.router.navigate(['/search'], {
        queryParams: { doctorname: searchText },
      });
    } else {
      this.router.navigate(['/search']);
      // Handle the case where searchText is undefined or falsy.
      // You might want to show an error message or take appropriate action.
    }
  }

  ngAfterViewInit() {
    this.initializeSwiper();
  }

  initializeSwiper() {
    this.swiper = new Swiper('.swiper', {
      speed: 2000,
      spaceBetween: 0,
      slidesPerView: 1,
      loop: true,
      autoplay: {
        delay: 4000,
      },
      modules: [Navigation, Pagination, Autoplay, EffectFade], // Add EffectFade module
      effect: 'fade', // Set the effect to fade
      fadeEffect: {
        crossFade: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
      },
    });
  }

  triggerAnimations() {
    const activeSlide = this.swiper.slides[this.swiper.activeIndex];
    activeSlide.classList.remove('triggered');
    setTimeout(() => {
      activeSlide.classList.add('triggered');
    }, 100);
  }

  servicesImageUrl = [
    { serviceName: 'Diagonstic Center', imgUrl: '/assets/banner/diagn.webp' },
    {
      serviceName: 'Ambulance Service',
      imgUrl: 'assets/banner/ambulance.png',
    },
    { serviceName: 'Order Medicine', imgUrl: 'assets/banner/medical.png' },
    {
      serviceName: 'Blood Donation Service',
      imgUrl: 'assets/banner/donate.jpg',
    },
    { serviceName: 'Sample Collection', imgUrl: 'assets/banner/test.png' },
  ];

  getPlatformList() {
    this.PlatformFacilityService.getList()
      .pipe(
        map((res) => {
          return res.map((service) => {
            const matchingServiceImageUrl = this.servicesImageUrl.find(
              (s) => s.serviceName === service.serviceName
            );
            if (matchingServiceImageUrl) {
              return {
                ...service,
                imgUrl: matchingServiceImageUrl.imgUrl,
              };
            } else {
              return service;
            }
          });
        })
      )
      .subscribe({
        next: (modifiedServiceList) => {
          this.serviceList = modifiedServiceList;
          console.log(this.serviceList);
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  goToForgotPassword() {
    this.router.navigate(['/forgot-password'], {
      queryParams: { redirect: 'login' },
    });
  }
  onClickService() {
    let emergency = document.getElementById('emergency');

    if (emergency) {
      emergency.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
