import {
  animate,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component } from '@angular/core';
import { delay } from 'rxjs';

export interface TitleProps {
  title: string;
  subtitle: string;
  blodText?: string;
}
@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrl: './services.component.scss',
  animations: [
    trigger('slideInTop', [
      transition('* => *', [
        query(
          ':enter',
          [
            style({ transform: 'translateY(-50px)', opacity: 0 }),
            stagger(300, [
              animate(
                '600ms ease-out',
                style({ transform: 'translateY(0)', opacity: 1 })
              ),
            ]),
          ],
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ServicesComponent {
  titleData: TitleProps = {
    title: 'Ensure Your Quality Healthcare from',
    subtitle: 'Our Service',
    blodText: 'Soowgood',
  };
  serviceDetails: any[] = [
    {
      serviceName: 'Doctor Consultation',
      description: 'string',
      slug: 'string',
      link: 'search',
      imgUrl: 'assets/service/s-dr.svg',
    },
    {
      serviceName: 'Medical Test & Hospitals',
      description: 'string',
      slug: 'string',
      link: 'hospitals',
      imgUrl: 'assets/doctor/test-tube.svg',
    },

    {
      serviceName: 'Order Medicine',
      description: 'string',
      slug: 'string',
      link: 'medicine',
      imgUrl: 'assets/doctor/medicine.svg',
    },
    {
      serviceName: ' Nurse/Caregiver ',
      description: 'string',
      slug: 'string',
      link: 'https://www.claracareservices.com/',
      imgUrl: 'assets/service/s-eq.svg',
    },
    {
      serviceName: 'Book Ambulance',
      description: 'string',
      slug: 'string',
      link: '/ambulance',
      imgUrl: 'assets/doctor/ambulance.svg',
    },
    {
      serviceName: 'Blood Donation',
      description: 'string',
      slug: 'string',
      link: 'blood-donation',
      imgUrl: 'assets/doctor/blood.svg',
    },
  ];
}
