<div class="min-h-screen bg-gray-100">
  <div class="bg-primary h-[150px] text-white flex justify-center items-center">
    <h1 class="text-3xl font-bold text-center">
      Hospital,Pathology/Diagnostic Center
    </h1>
  </div>
  <div class="container py-10">
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      <!-- Advance Hospital Card -->
      <div class="bg-white shadow-md rounded-lg overflow-hidden">
        <img
          src="assets/other/advance.png"
          alt="Advance Hospital"
          class="w-full h-48 object-cover"
        />
        <div class="p-6">
          <h2 class="text-2xl font-bold text-gray-800 mb-2">
            Advance Hospital
          </h2>
          <p class="text-gray-600 mb-4">House: 01, F Block, Banasree, Dhaka</p>
          <a
            href="https://advancehospitalltd.com/"
            class="bg-secondary text-white py-2 px-4 rounded-lg hover:bg-green-600 transition duration-300"
            >Visit Now</a
          >
        </div>
      </div>

      <!-- New Life Hospital (Pvt). Ltd. Card -->
      <div class="bg-white shadow-md rounded-lg overflow-hidden">
        <img
          src="assets/other/newlife.jpeg"
          alt="New Life Hospital (Pvt). Ltd."
          class="w-full h-48 object-cover"
        />
        <div class="p-6">
          <h2 class="text-2xl font-bold text-gray-800 mb-2">
            New Life Hospital (Pvt). Ltd.
          </h2>
          <p class="text-gray-600 mb-4">Green Road, Tejgaon, Dhaka</p>
          <a
            href="https://www.facebook.com/newlifehospitalPvtLtd/"
            class="bg-secondary text-white py-2 px-4 rounded-lg hover:bg-green-600 transition duration-300"
            >Visit Now</a
          >
        </div>
      </div>

      <!-- Green Specialized Hospital (Pvt). Ltd. Card -->
      <div class="bg-white shadow-md rounded-lg overflow-hidden">
        <img
          src="assets/other/green.jpeg"
          alt="Green Specialized Hospital (Pvt). Ltd."
          class="w-full h-48 object-cover"
        />
        <div class="p-6">
          <h2 class="text-2xl font-bold text-gray-800 mb-2">
            Green Specialized Hospital (Pvt). Ltd.
          </h2>
          <p class="text-gray-600 mb-4">Begum Rokeya Avenue, Dhaka</p>
          <a
            href="https://www.facebook.com/profile.php?id=61557331123403&paipv=0&eav=Afb5IPw1AEGVOwtXnJ4uzCbrDowRZ4ExGrB9Ce-FcISkvGHs1QTm8dcReC91y7UYmDY"
            class="bg-secondary text-white py-2 px-4 rounded-lg hover:bg-green-600 transition duration-300"
            >Visit Now</a
          >
        </div>
      </div>
      <div class="bg-white shadow-md rounded-lg overflow-hidden">
        <img
          src="/assets/other/estern.jpeg"
          alt="Eastern Diagnostic Center"
          class="w-full h-48 object-cover"
        />
        <div class="p-6">
          <h2 class="text-2xl font-bold text-gray-800 mb-2">
            Eastern Diagnostic Center
          </h2>
          <p class="text-gray-600 mb-4">Eastern Commercial Complex, Dhaka</p>
          <a
            href="#"
            class="bg-secondary text-white py-2 px-4 rounded-lg hover:bg-green-600 transition duration-300"
            >Visit Now</a
          >
        </div>
      </div>

      <!-- Bangladesh Diagnostic & Medical Centre Card -->
      <div class="bg-white shadow-md rounded-lg overflow-hidden">
        <img
          src="/assets/other/bdh.jpeg"
          alt="Bangladesh Diagnostic & Medical Centre"
          class="w-full h-48 object-cover"
        />
        <div class="p-6">
          <h2 class="text-2xl font-bold text-gray-800 mb-2">
            Bangladesh Diagnostic & Medical Centre
          </h2>
          <p class="text-gray-600 mb-4">
            205/1, 2nd Floor, Shohid Syed NAZRUL Islam, Bijoy Nagar Road,
            1000Soroni
          </p>
          <a
            href="https://www.bangladeshdiagnostic.com/"
            class="bg-secondary text-white py-2 px-4 rounded-lg hover:bg-green-600 transition duration-300"
            >Visit Now</a
          >
        </div>
      </div>

      <!-- Prime Diagnostic Limited Card -->
      <div class="bg-white shadow-md rounded-lg overflow-hidden">
        <img
          src="/assets/other/prime.png"
          alt="Prime Diagnostic Limited"
          class="w-full h-48 object-cover"
        />
        <div class="p-6">
          <h2 class="text-2xl font-bold text-gray-800 mb-2">
            Prime Diagnostic Limited
          </h2>
          <p class="text-gray-600 mb-4">
            2, Rupayan FPAB Tower, Culvert Road, Dhaka
          </p>
          <a
            href="https://primediagnosticltd.com/"
            class="bg-secondary text-white py-2 px-4 rounded-lg hover:bg-green-600 transition duration-300"
            >Visit Now</a
          >
        </div>
      </div>
      <!-- Add more hospital cards as needed -->
    </div>
  </div>
</div>
