<div class="container-full flex justify-center h-screen items-center">
  <div class="bg-transparent w-[80%] grid grid-cols-1 md:grid-cols-2">
    <!-- Left Column: Content -->
    <div class="p-8 flex flex-col justify-center">
      <h1 class="text-lg font-poppinsBold text-secondary">COMING SOON</h1>
      <h2 class="text-[32px] font-RobotoBold text-gray-700">
        Get your recent update please subscribe
      </h2>

      <p class="text-gray-600 mt-4">
        We're putting the finishing touches on our website and getting ready to
        launch. Sign up for updates and be the first to know when we go live.
      </p>

      <form class="mt-6">
        <div class="mb-4">
          <input
            type="email"
            placeholder="Email address"
            class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <button
          type="submit"
          class="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Subscribe
        </button>
      </form>

      <p class="text-gray-500 text-sm mt-6">
        Sign up for updates to be the first to know when we launch. No spam,
        just important information and exclusive offers.
      </p>
    </div>

    <!-- Right Column: Image -->
    <div class="hidden md:block">
      <img
        src="assets/banner/fixing.webp
        "
        alt="Coming Soon Image"
        class="w-full h-full object-fill rounded-r-lg"
      />
    </div>
  </div>
</div>
